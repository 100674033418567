.MuiDataGrid-columnHeaderTitle {
  color: #747980;
  background-color: transparent !important;
}
.MuiDataGrid-columnHeaders {
  border-bottom: none !important;
}

.MuiDataGrid-row {
  margin-bottom: 10px;
  border-radius: 10px;
  min-height: 60px !important;
  max-height: 60px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
  border: 1px solid #e2e5ed !important;
}

.MuiDataGrid-root {
  border: none !important;
}
.MuiDataGrid-cell {
  border: none !important;
  outline: none !important;
}
.MuiDataGrid-footerContainer {
  border-top: none !important;
}
.no-scroll .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none;
}
