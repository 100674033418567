.user-info-item{
    border: 2px solid #F1F1F1;
    border-radius: 2rem;
    padding: 0.3rem 1rem;
    margin: 0.3rem 0.3rem;
    color: #AAAAAA;
    font-size: 13px;
}

.user-profile-selected-item{
    background-color: #33CC99;
    color: #ffffff;
}