.user-profile-wrapper {
    padding-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
}

.user-profile-picture img {
    width: 13rem;
    height: 12rem;
    border-radius: 2.2rem;
}

.user-profile-name-age{
    margin: 0;
}

.user-profile-main-card {
    display: flex;
    width: 75%;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 11px 4px rgba(227, 227, 227, 0.25);
    border-radius: 10px;
    padding: 2rem 1.5rem;
}

.user-profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
}

.user-profile-language-prefs {
    display: flex;
}

.user-profile-language-prefs p {
    margin: 0;
    margin-left: 0.5rem;
}

.language-prefs-text {
    font-size: 15px;
}

.language-prefs-first {
    color: #AAAAAA;
}

.user-info-activities {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 65%;
    padding: 0.5rem 1rem;
    margin-right: 1.5rem;
    border: solid 0.3rem #F1F1F1;
    border-radius: 1.5rem;
}

.user-info-hobbies {
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    padding: 0.5rem 1rem;
    border: solid 0.3rem #F1F1F1;
    border-radius: 1.5rem;
}

.interests-info {
    margin-top: 2rem;
    width: 75%;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 11px 4px rgba(227, 227, 227, 0.25);
    border-radius: 10px;
    padding: 2rem 1.5rem;
}

.activities-display {
    display: flex;
    flex-wrap: wrap;
}

.free-time-activities {
    color: #AAAAAA;
}

.hobbies-display {
    display: flex;
    flex-wrap: wrap;
}