body {
  overflow: hidden;
}

.about-us-container{
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  padding-top: 0.75rem;
  font-family: "Montserrat Medium";
}

.about-us-content {
  background-color: #ffffff;
  margin-top: 100px;
  padding: 20px;
  text-align: center;
  color: #33CC99;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 11px 4px rgba(227, 227, 227, 0.25);
  border-radius: 10px;
  margin-left: 15%;
  width: 70%;
}

.about-us-content h2{
  font-weight: bold;
}

.about-us-text {
  color: #000000;
  max-width: 1000px;
  /* Adjust the max-width value as needed */
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}