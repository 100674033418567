.student-request-table {
  width: 95%;
  height: 100%;
}
.student-request-container {
  margin-left: 2rem;
  padding-top: 2rem;
}
.student-request-title {
  margin-left: 1rem;
  margin-bottom: 2rem;
}
.student-request-search {
  margin-bottom: 1rem;
}
