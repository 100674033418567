.multiple-choice-container {
    display: flex;
    justify-content: space-between;
    width: 35%;
}

.multiple-choice-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
}

.multiple-choice-header{
    width: 35%;
    text-align: left;
    color: #33CC99;
    background-color: #D9D9D9;
    border-color: #D9D9D9;
    padding: 0.4rem;
    border-radius: 0.3rem;
    margin-bottom: 0.6rem;
    border: 1px solid #ccc;
    font-size: 0.9rem;
}