p{
    text-align: left;
}

.landing-container{
    display: flex;
    justify-content: center;
    position: relative;
    top: 200px;
}

.landing-wrapper{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landing-text{
    color: #001737;
}

.student-life p{
    margin: 0px;
}

.student-life{
    font-size: 32px;
}

.student-life-green{
    color: #33CC99;
}

.landing-image img{
    width: 350px;
}

.explore-more-button{
    background-color: #001737;
    margin-top: 30px;
    display: flex;
}

.are-you-looking{
    width: 400px;
    margin-top: 20px;
    margin-bottom: 0px;
}

@media only screen and (max-width: 900px) {
    .landing-image{
        display: none;
    }

    .landing-container{
        display: block;
    }

    .landing-wrapper{
        width: 100%;
        display: block;
        padding: 10px;
    }

    .are-you-looking{
        width: 100%;
    }
}