.pre-registration-card-wrapper {
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 11%) 0px 5px 15px;
    margin-bottom: 10px;
}

.card-icon img {
    width: 35px;
}

.arrow-icon img {
    width: 15px;
}

.icon-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-text {
    margin-left: 20px;
}

@media only screen and (max-width: 900px) {
    .pre-registration-card-wrapper {
        width: 100%;
    }
}