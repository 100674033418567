.navigation-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
  background-color: #FFFFFF;
}

.pre-registration-navigation-bar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navigation-bar {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  padding: 1.8rem 0rem;
  width: 60%;
}

.pre-registration-navigation-bar {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-around;
  padding: 1.8rem 0rem;
  width: 60%;
}

.default-navigation-bar {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.4rem 0rem;
}

.navigation-bar__list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: space-between;
}

.navigation-bar__item {
  margin-right: 20px;
}

.navigation-bar__item:last-child {
  margin-right: 0;
}

.navigation-bar__item a {
  color: #001737;
  text-decoration: none;
}

.logo-section {
  width: 33%;
  display: flex;
  align-items: center;
}

.logo-section .semiBold-font {
  font-size: x-large;
  color: #33CC99;
}

.logo {
  width: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.contact-section {
  display: flex;
  align-items: center;
  width: 33%;
}

.login-button {
  text-decoration: none;
  color: #001737;
  margin-right: 25px;
}

.create-account-button {
  background-color: #33CC99;
}

.account-section {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.nav-logged-in-section {
  display: flex;
}

.nav-logged-in-section-item {
  color: #001737;
  margin-left: 0.1rem;
  cursor: pointer;
  padding: 0.1rem 1rem;
  border-radius: 5px;
}

.default-navigation-bar-selected-option {
  background: #33CC99;
  color: white;
}

.logged-in-section{
  display: flex;
  align-items: center;
}

.logged-in-section > p{
  margin: 0;
}

.logged-in-section span{
  color: #33CC99;
}

@media only screen and (max-width: 900px) {
  .pre-registration-navigation-bar {
    width: 100%;
  }

  .navigation-bar-wrapper {
    display: block
  }

  .navigation-bar {
    width: 100%;
    justify-content: space-around;
  }

  .logo-section {
    width: auto;
  }

  .account-section {
    width: auto;
  }

  .contact-section {
    display: none;
  }

  .account-section {
    font-size: 13px;
  }
}