.choice-column {
    width: 49%;
}

.choice-option {
    padding: 0.3rem;
    margin-bottom: 0.6rem;
    border-radius: 0.3rem;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
}

.choice-option-selected{
    background-color: #D9D9D9;
    border-color: #D9D9D9;
}