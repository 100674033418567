html, body, #app, #app, #root, .App>div {
    height: 100%
}
.contact-us-page-container{
    width: 100%;
    height: 100%;
    background-color: #EDEBEB;
}
.contact-us-blue-container{
    margin-left: 20%;
    position: fixed;
    width: 40%;
    height: 100%;
    background-color: #001737;
}
.contact-us-container{
    padding-top: 35px;
    margin-left: 10%;
    position: fixed;
    margin-top:7%;
    width: 55%;
    height: 70%;
    background-color: #FFFFFF;
}
.contact-us-form{
    width: 60%;
    padding: 5%;
}
.contact-us-image{
    position: fixed;
    margin-top: -20px;
    margin-left: 33%;
    width: 600px;
    height: 430px;
;
}
.align-left{
    text-align: left;
}

.color-blue{
    color: #001737
}
.color-green{
    color:#33CC99
}
.float-left{
    float: left;
}
.no-padding{
    padding:0!important;
}
.width-100{
    width: 100%;
}
.margin-right{
    margin-right: 30px;
}
.margin-btm-m{
     margin-bottom: 30px;
 }
.margin-btm-sml{
    margin-bottom: 10px;
}
.margin{
    margin-left: 0;
    margin-right: 0;
}
.label-font{
    fontSize: 14
}
.contact-us-submit-button{
    width: 60%;
    height: 50px;
    margin-left: 20%;
}