.uni-profile-picture-wrapper{
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.uni-upload_picture_label{
    width: 200px;
    height: 200px;
    cursor: pointer;
}

.uni-upload_picture_label img{
    width: 100%;
    height: 100%;
}

.uni-onboarding-profile-p{
    font-size: 1rem;
}