.feed-container {
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  padding-top: 7rem;
  font-family: "Montserrat Medium";
  color: #001737;
}

.feed-container > div:not(:first-child) {
  margin-left: 15%;
  width: 70%;
  background-color: #ffffff;
  box-shadow: 0px 4px 11px 4px rgba(227, 227, 227, 0.25);
  border-radius: 10px;
}
.feed-card-content {
  display: flex;
  column-gap: 4rem;
  padding-top: 3%;
  padding-left: 10%;
}

.university-card {
  height: 18rem;
}
.mar-bott-sml {
  margin-bottom: 0.3rem;
}
.mar-bott-m {
  margin-bottom: 1.25rem;
}
.university-image {
  border-radius: 10px;
}
.feed-title-1 {
  color: #8c8c8c;
  font-size: 0.8rem;
}
.progress-bar-wrapper {
  width: 70%;
}
.university-content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 40%;
}
.university-content-wrapper h4{
  font-weight: bold;
}
.university-text {
  font-size: 0.8rem;
  color: gray;
  margin-bottom: 1.85rem;
  margin-top: 0.6rem;
}

.feed-percentage {
  font-weight: bold;
  padding-left: 5%;
}

.get-started-div{
  font-size: 1.2rem;
}

.requested-match-div{
  font-size: 1.2rem;
  color: #33CC99;
}

.feed-disclaimer{
  color: #33CC99;
  font-size: 0.7rem;
  margin-top: 0.2rem;
}
@media only screen and (max-width: 700px) {
  .feed-card-content {
    display: block;
    align-content: center;
    width: 100%;
    margin: auto;
    padding-bottom: 5%;
    padding-right: 10%;
  }

  .progress-bar-wrapper {
    width: 90%;
  }
  .university-image {
    width: 100%;
    height: fit-content;
  }
  .university-content-wrapper {
    max-width: unset;
  }
  .university-card {
    height: unset;
  }
}
