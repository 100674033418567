.create-your-account {
    color: #001737;
    text-align: left;
    font-size: 20px;
}

.pre-registration-wrapper {
    display: flex;
}

.pre-registration-image img {
    width: 100%;
    height: 100%;
}

.create-account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.leftHalf {
    width: 60%;
    position: absolute;
    left: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-label{
    color: #8A8A8A;
    margin-left: 12px;
    cursor: pointer;
}

.arrow-back-wrapper{
    border-bottom: 1px solid #8A8A8A;
}

.left-arrow-icon{
    cursor: pointer;
}

.rightHalf {
    background: url('../../assets/images/male-student-pic.jpeg');
    background-repeat: no-repeat;
    background-size:cover ;
    width: 40%;
    position: absolute;
    right: 0px;
    height: 100%;
}

@media only screen and (max-width: 900px) {
    .rightHalf {
        display: none;
    }

    .leftHalf {
        width: 100%;
    }

    .preregistration-card-wrapper{
        width: 90%;
    }
}