
.Mui-selected.sidebar-item {
    background: rgba(51, 204, 153, 0.24) !important;
    border-left: 5px solid #33CC99;
}

.sidebar-button{
    min-height: 60px!important;
    justify-content: initial!important;
}


.sidebar-item-icon{
    justify-content: center!important;
    color: #001737!important;
    min-width: 40px!important;

}
.sidebar-item-text {
    opacity: 1!important;
    color: #001737!important;
}

