.education-wrapper{
    margin-top: 3rem;
}

.choose-primary-education-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-study{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-study-second{
    margin-top: 3rem;
}