.Mui-selected.university-setting-tab{
    color:#33CC99!important;
}
.MuiTab-root.university-setting-tab:hover{
    color:#33CC99!important;
}
.university-setting-tab{
    border-bottom: 1px gray solid!important;
}
.MuiTabs-indicator{
    background-color: #33CC99!important;
}
.uni-info{
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
    padding-top: 6rem;
    font-family: "Montserrat Medium";
    color: #001737;
}