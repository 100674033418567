.onb-motivation-p-first{
    margin: 0;
}

.onb-motivation-p-second{
    margin: 0;
    margin-top: 1rem;
}

.onb-motivation-disclaimer-p{
    color: #33CC99;
    font-size: 0.6rem;
    margin: 0;
}

.style-motivation-p-text{
    font-size: 0.8rem;
}