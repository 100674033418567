html,
body,
#app,
#root,
.App > div {
  height: 100%;
}

.feedback-page-container {
  width: 100%;
  height: 100%;
  background-color: #edebeb;
  margin-left: 70px;
}

.feedback-container {
  padding-top: 35px;
  margin-left: 10%;
  position: fixed;
  margin-top: 7%;
  width: 55%;
  height: 70%;
  background-color: #ffffff;
}

.feedback-form {
  width: 60%;
}

.feedback-image {
  position: fixed;
  margin-top: -20px;
  margin-left: 37%;
  width: 600px;
  height: 430px;
}

.align-left {
  text-align: left;
}

.color-blue {
  color: #001737;
}

.color-green {
  color: #33cc99;
}

.float-left {
  float: left;
}

.no-padding {
  padding: 0 !important;
}

.width-100 {
  width: 100%;
}

.margin-right {
  margin-right: 30px;
}

.margin-btm-m {
  margin-bottom: 30px;
}

.margin-btm-sml {
  margin-bottom: 10px;
}

.margin {
  margin-left: 0;
  margin-right: 0;
}

.label-font {
  font-size: 14px;
}

.feedback-submit-button {
  width: 60%;
  height: 50px;
  margin-left: 20%;
}

/* Mobile responsive styles */

@media (max-width: 768px) {
  .feedback-blue-container {
    width: 100%;
    margin-left: 0;
  }

  .feedback-container {
    margin-left: 0;
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 0;
  }

  .feedback-image {
    margin-left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    height: auto;
  }

  .feedback-form {
    width: 80%;
    margin-left: 10%;
  }

  .feedback-submit-button {
    width: 80%;
    margin-left: 10%;
  }
}