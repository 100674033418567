.contact-container-main {
    top: 5rem;
    position: relative;
    padding: 2rem;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .contact-form-main {
    width: 100%;
    max-width: 820px;
    background-color: #33cc99;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .color-green {
    color: #33cc99;
  }
  
  .contact-form {
    background-color: #fff;
    position: relative;
  }
  
  .contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #33cc99;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
  }
  
  .contact-form-tag {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
  }
  
  .title {
    color: #fff;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
  }
  
  .feedback-submit-button{
    color: #33cc99;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 1000;
    transition: 0.5s;
  }
  
  .input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .title{
    color:#fafafa;
  }
  
  .btn {
    padding: 0.6rem 1.3rem;
    background-color: #ffffff;
    border: 2px solid #ffffff;
    font-size: 0.95rem;
    color: #33cc99;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
  .input-container span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .input-container span:before,
  .input-container span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #33cc99;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container span:before {
    left: 50%;
  }
  
  .input-container span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .input-container.focus span:before,
  .input-container.focus span:after {
    width: 50%;
    opacity: 1;
  }
  
  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info .title {
    color: #ffffff;
  }
  
  .text {
    color: #ffffff;
    margin: 1.5rem 0 2rem 0;
  }
  
  .information {
    display: flex;
    color: #ffffff;
    font-size: 0.95rem;
  }
  
  .icon {
    width: 28px;
    margin-top: 2px;
    margin-right: 0.7rem;
  }
  
  .social-media {
    padding: 2rem 0 0 0;
  }
  
  .social-media p {
    color: #ffffff;
  }
  
  .social-icons {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #1abc9c, #149279);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
  .contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #fafafa;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
  }
  
  .square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
  }
  
  @media (max-width: 850px) {
    .form {
      grid-template-columns: 1fr;
    }
  
    .contact-info:before {
      bottom: initial;
      top: -75px;
      right: 65px;
      transform: scale(0.95);
    }
  
    .contact-form:before {
      top: -13px;
      left: initial;
      right: 70px;
    }
  
    .square {
      transform: translate(140%, 43%);
      height: 350px;
    }
  
    .text {
      margin: 1rem 0 1.5rem 0;
    }
  
    .social-media {
      padding: 1.5rem 0 0 0;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 1.5rem;
    }
  
    .contact-info:before {
      display: none;
    }
  
    .square,
    .big-circle {
      display: none;
    }
  
    form,
    .contact-info {
      padding: 1.7rem 1.6rem;
    }
  
    .text,
    .information,
    .social-media p {
      font-size: 0.8rem;
    }
  
    .title {
      font-size: 1.15rem;
    }
  
    .social-icons a {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .icon {
      width: 23px;
    }
  
    .input {
      padding: 0.45rem 1.2rem;
    }
  
    .btn {
      padding: 0.45rem 1.2rem;
    }
  }