.main-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-buttons-wrapper {
    width: 85%;
    font-size: 12px;
    position: absolute;
    bottom: 5.5rem;
}

.back-button {
    outline: none;
    background-color: #fff;
    border: 1px solid #33CC99;
    color: #33CC99;
    width: 9rem;
    height: 2.3rem;
    border-radius: 3rem;
    float: left;
}

.continue-button {
    outline: none;
    border: none;
    background-color: #33CC99;
    color: #fff;
    width: 9rem;
    height: 2.3rem;
    border-radius: 3rem;
    float: right;
}

.continue-button-disabled {
    outline: none;
    border: none;
    background-color: #ccc;
    color: #fff;
    width: 9rem;
    height: 2.3rem;
    border-radius: 3rem;
    float: right;
}

.skip-button{
    outline: none;
    border: none;
    color: rgb(85, 85, 211);
    background-color: white;
    width: 9rem;
    height: 2.3rem;
    border-radius: 3rem;
    float: right;
    font-size: 1rem;
}