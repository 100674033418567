* {
  box-sizing: border-box;
}

:root {
  --border-color: #e2e5ed;
  --background-hover: #f8f8fb;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
}

.fade-enter {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 0ms ease-in-out;
}

.fade-exit-active {
  opacity: 0;
}

/* Montserrat Fonts */
@font-face {
  font-family: "Montserrat Regular";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("./assets/fonts/montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("./assets/fonts/montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Thin";
  src: url("./assets/fonts/montserrat/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("./assets/fonts/montserrat/Montserrat-Light.ttf");
}

.regular-font {
  font-family: "Montserrat Regular";
}

.medium-font {
  font-family: "Montserrat Medium";
}

.bold-font {
  font-family: "Montserrat Bold";
}

.semiBold-font {
  font-family: "Montserrat SemiBold";
}

.thin-font {
  font-family: "Montserrat Thin";
}

.light-font {
  font-family: "Montserrat Light";
}
/* Fonts */

.landing-button {
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: "Montserrat SemiBold";
}
