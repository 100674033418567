.personal-wrapper{
    margin-top: 2rem;
}

.choose-language-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-language{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}