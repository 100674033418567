.choose-gender-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-gender{
    width: 20%;
    display: flex;
    justify-content: space-between;
}

.choose-gender img{
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.gender-selected{
    opacity: 1;
}

.gender-unselected{
    opacity: 0.2;
}

.personal-wrapper{
    margin-top: 2rem;
}

.choose-age-wrapper{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-age{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.choose-age p{
    margin: 0;
}

.personal-phone-number-wrapper{
    margin-top: 2rem;
}

.personal-phone-number{
    display: flex;
    flex-direction: column;
    align-items: center;
}